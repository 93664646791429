<template>
    <div>
        <div class="top">
            <div class="style-5 iconfont" :style="iconStyle">&#xe851;</div>
            <div class="line-title"  v-if="!column.titleHide" :style="lineTitleStyle">
            {{ column.titleName || '分割线' }}
        </div>
        </div>
        <div class="line-desc" v-if="!column.LineDescHide" :style="lineDescStyle">
            {{ column.LineDesc }}
        </div>
    </div>
  </template>
  <script>

  export default {
    name: "styleSevenLine",
    props: {
      column: {
        type: Object,
        require: true,
        default() {
          return {};
        }
      },
      // 可能需要的整体数据
      businessData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
        iconStyle() {
            return {
                color: this.column.LineTypeColor
            };
        },
        lineDescStyle() {
            return {
                "font-size": this.column.LineDescFontsize,
                color: this.column.LineDescFontcolor,
                 "font-weight": this.column.LineDescFontbold ? "bold" : 400
            };
        },
        lineTitleStyle() {
            return {
                "font-size": this.column.titleFontsize,
                color: this.column.titleFontcolor,
                "font-weight": this.column.titleFontbold ? "bold" : 100
            };
        }
    }
  };
  </script>
  <style scoped lang="scss">
  .top{
    display: flex;
    align-items: center;
    .style-5{
        margin-right: 10px;
        width: 16px;
        color: #3E90FE;
    }
  }
  </style>
