<template>
  <div>
    <div class="line-box">
        <div class="line-title" :style="{'--bg':beforeStyle, ...lineTitleStyle}">
        <span v-if="!column.titleHide">{{ column.titleName || '分割线' }}</span>
      </div>
    </div>
    <div class="line-desc" v-if="!column.LineDescHide" :style="lineDescStyle">
            {{ column.LineDesc }}
    </div>
  </div>
  </template>
  <script>

  export default {
    name: "styleelevenLine",
    props: {
      column: {
        type: Object,
        require: true,
        default() {
          return {};
        }
      },
      // 可能需要的整体数据
      businessData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
        return {
          bg: "#3E90FE"
        };
    },
    computed: {
        lineDescStyle() {
            return {
                "font-size": this.column.LineDescFontsize,
                color: this.column.LineDescFontcolor,
                 "font-weight": this.column.LineDescFontbold ? "bold" : 400
            };
        },
        beforeStyle() {
          return this.column.LineTypeColor ? this.column.LineTypeColor : "#3E90FE";
        },
        lineTitleStyle() {
            return {
                "font-size": this.column.titleFontsize,
                color: this.column.titleFontcolor,
                "font-weight": this.column.titleFontbold ? "bold" : 400,
                "background-color": this.column.LineTypeColor
            };
        }
    }
  };
  </script>
  <style scoped lang="scss">
.line-box{
    display: flex;
}
.line-title {
  display: flex;
  align-items: center;
  justify-content: center;
    position: relative;
    min-width: 152px;
    height: 30px;
    padding: 0 10px;
    border-radius: 14px 14px 0px 0px;
    color: #fff;
    background-color: #3E90FE;
}
.line-title:before{
  position: absolute;
  content: '';
  bottom: 0;
  right: -93px;
  width:93px;
  height: 1px;
  background: var(--bg);
}
  </style>
