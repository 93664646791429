<template>
    <div>
        <div class="icon-box">
            <div class="line-title" :style="lineTitleStyle">
            <span  v-if="!column.titleHide">{{ column.titleName || '分割线' }}</span>
            </div>
            <div class="parallelogram" :style="parallelogramStyle"></div>
            <div class="parallelogram-small" :style="parallelogramStyle"></div>
        </div>
        <div class="line-desc" v-if="!column.LineDescHide" :style="lineDescStyle">
            {{ column.LineDesc }}
        </div>
    </div>
  </template>
  <script>

  export default {
    name: "styleTenLine",
    props: {
      column: {
        type: Object,
        require: true,
        default() {
          return {};
        }
      },
      // 可能需要的整体数据
      businessData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
        return {
        };
    },
    computed: {
        lineDescStyle() {
            return {
                "font-size": this.column.LineDescFontsize,
                color: this.column.LineDescFontcolor,
                 "font-weight": this.column.LineDescFontbold ? "bold" : 400
            };
        },
        parallelogramStyle() {
            return {
                "background-color": this.column.LineTypeColor
            };
        },
        lineTitleStyle() {
            return {
                "font-size": this.column.titleFontsize,
                color: this.column.titleFontcolor,
                "font-weight": this.column.titleFontbold ? "bold" : 400,
                "background-color": this.column.LineTypeColor
            };
        }
    }
  };
  </script>
  <style scoped lang="scss">
.icon-box{
    display: flex;
}
.line-title {
    position: relative;
    min-width: 160px;
    height: 30px;
    padding: 0 10px;
    border-radius: 4px 0 0 4px;
    color: #fff;
    background-color: #3E90FE;
    clip-path: polygon(0 0, 84% 0, 100% 100%, 0% 100%);
}
.parallelogram{
    width: 25px;
    height: 30px;
    background: #3E90FE;
    opacity: 0.5;
    transform: skewX(40.5deg);
    margin-left: -13px;
}
.parallelogram-small{
    width: 15px;
    height: 30px;
    background: #3E90FE;
    opacity: 0.2;
    transform: skewX(40.5deg);
    margin-left: 3px;
}
  </style>
