var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "top" }, [
      _c("div", {
        staticClass: "style-5 iconfont iconfengexianbiaotiyangshi8",
        style: _vm.iconStyle,
      }),
      !_vm.column.titleHide
        ? _c("div", { staticClass: "line-title", style: _vm.lineTitleStyle }, [
            _vm._v(" " + _vm._s(_vm.column.titleName || "分割线") + " "),
          ])
        : _vm._e(),
    ]),
    !_vm.column.LineDescHide
      ? _c("div", { staticClass: "line-desc", style: _vm.lineDescStyle }, [
          _vm._v(" " + _vm._s(_vm.column.LineDesc) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }