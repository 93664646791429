<template>
    <div>
        <div class="line-box">
            <div class="shu" :style="beforeStyle"></div>
            <div class="line-title" v-if="!column.titleHide" :style="lineTitleStyle">
                <span>{{ column.titleName || '分割线' }}</span>
            </div>
        </div>
        <div class="line-desc" v-if="!column.LineDescHide" :style="lineDescStyle">
                {{ column.LineDesc }}
        </div>
     </div>
  </template>
  <script>

  export default {
    name: "styleThirteenLine",
    props: {
      column: {
        type: Object,
        require: true,
        default() {
          return {};
        }
      },
      // 可能需要的整体数据
      businessData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
        return {
          bg: "#3E90FE"
        };
    },
    computed: {
        lineDescStyle() {
            return {
                "font-size": this.column.LineDescFontsize,
                color: this.column.LineDescFontcolor,
                "font-weight": this.column.LineDescFontbold ? "bold" : 400
            };
        },
        beforeStyle() {
          return { background: this.column.LineTypeColor ? this.column.LineTypeColor : "#3E90FE" };
        },
        lineTitleStyle() {
            return {
                "font-size": this.column.titleFontsize,
                color: this.column.titleFontcolor,
                "font-weight": this.column.titleFontbold ? "bold" : 400,
                "background-color": this.column.LineTypeColor
            };
        }
    }
  };
  </script>
  <style scoped lang="scss">
.line-box{
    display: flex;
}
.shu{
    width: 4px;
    height: 30px;
    background: #3E90FE;
    margin-right: 4px;
}
.line-title {
  display: flex;
  align-items: center;
    min-width: 152px;
    height: 30px;
    padding: 0 15px 0 11px;
    border-radius: 0px 15px 15px 0px;
    color: #fff;
    background-color: #3E90FE;
}
  </style>
