<!--
 * @Author: pengyu
 * @Date: 2020-07-27 15:21:02
 * @LastEditors: pengyu
 * @LastEditTime: 2020-11-14 09:55:27
 * @Description: 分割线
-->
<template>
  <div class="seperator-line">
    <!-- <div
      class="line"
      :class="{'dashed':column.lineType === 2,'solid':!column.lineType || column.lineType === 1,'space':column.lineType === 3}"
    >
    </div> -->
    <solid v-if="column.lineType === 1" :column="column.lineTypeCss"></solid>
    <dashed v-if="column.lineType === 2" :column="column.lineTypeCss"></dashed>
    <space v-if="column.lineType === 3" :column="column.lineTypeCss"></space>
    <styleFourLine v-if="column.lineType === 4" :column="column.lineTypeCss"></styleFourLine>
    <styleFiveLine v-if="column.lineType === 5" :column="column.lineTypeCss"></styleFiveLine>
    <styleSixLine v-if="column.lineType === 6" :column="column.lineTypeCss"></styleSixLine>
    <styleSevenLine v-if="column.lineType === 7" :column="column.lineTypeCss"></styleSevenLine>
    <styleEightLine v-if="column.lineType === 8" :column="column.lineTypeCss"></styleEightLine>
    <styleNineLine v-if="column.lineType === 9" :column="column.lineTypeCss"></styleNineLine>
    <styleTenLine v-if="column.lineType === 10" :column="column.lineTypeCss"></styleTenLine>
    <styleelevenLine v-if="column.lineType === 11" :column="column.lineTypeCss"></styleelevenLine>
    <styleTwelveLine v-if="column.lineType === 12" :column="column.lineTypeCss"></styleTwelveLine>
    <styleThirteenLine v-if="column.lineType === 13" :column="column.lineTypeCss"></styleThirteenLine>
  </div>
</template>
<script>
import MxColumnData from "../MxColumnData.js";
import solid from "./solid-line";
import dashed from "./dashed-line";
import space from "./space-line";
import styleFourLine from "./style4-line";
import styleFiveLine from "./style5-line";
import styleSixLine from "./style6-line";
import styleSevenLine from "./style7-line";
import styleEightLine from "./style8-line";
import styleNineLine from "./style9-line";
import styleTenLine from "./style10-line";
import styleelevenLine from "./style11-line";
import styleTwelveLine from "./style12-line";
import styleThirteenLine from "./style13-line";

export default {
  name: "BMSeperatorLineComponent",
  mixins: [MxColumnData],
  components: {
    solid,
    dashed,
    space,
    styleFourLine,
    styleFiveLine,
    styleSixLine,
    styleSevenLine,
    styleEightLine,
    styleNineLine,
    styleTenLine,
    styleelevenLine,
    styleTwelveLine,
    styleThirteenLine
  },
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>
<style scoped lang="scss">
.seperator-line {
  width: 100%;
  .dashed {
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, #dce6ef 0%, #dce6ef 50%, transparent 50%);
    background-size: 10px 1px;
    background-repeat: repeat-x;
  }
  .solid {
    width: 100%;
    height: 1px;
    transform: scaleY(0.5);
    background: #dce6ef;
  }
  .space {
    width: 100%;
    height: 8px;
    background: rgba(232, 236, 242, 1);
  }
}
.line-title{
    color:#333333;
  }
  .line-desc{
    color:#636C78;
  }
  .line{
    margin-top:14px;
    width: 100%;
    border-top: 1px solid #C1CAD7;
  }
</style>
