<template>
    <div>
        <div class="line-box">
            <div class="line-title" :style="lineTitleStyle">
                <span  v-if="!column.titleHide">{{ column.titleName || '分割线' }}</span>
                <div class="dian"></div>
            </div>
        </div>
        <div class="line-desc" v-if="!column.LineDescHide" :style="lineDescStyle">
            {{ column.LineDesc }}
        </div>
    </div>
  </template>
  <script>

  export default {
    name: "styleTwelveLine",
    props: {
      column: {
        type: Object,
        require: true,
        default() {
          return {};
        }
      },
      // 可能需要的整体数据
      businessData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
        return {
          bg: "#3E90FE"
        };
    },
    computed: {
        lineDescStyle() {
            return {
                "font-size": this.column.LineDescFontsize,
                color: this.column.LineDescFontcolor,
                 "font-weight": this.column.LineDescFontbold ? "bold" : 400
            };
        },
        beforeStyle() {
          return this.column.LineTypeColor ? this.column.LineTypeColor : "#3E90FE";
        },
        lineTitleStyle() {
            return {
                "font-size": this.column.titleFontsize,
                color: this.column.titleFontcolor,
                "font-weight": this.column.titleFontbold ? "bold" : 400,
                "background-color": this.column.LineTypeColor
            };
        }
    }
  };
  </script>
  <style scoped lang="scss">
.line-box{
    display: flex;
}
.line-title {
  display: flex;
  align-items: center;
  justify-content: center;
    position: relative;
    min-width: 152px;
    height: 30px;
    padding: 0 15px 0 25px;
    border-radius: 15px 15px 0px 15px;
    color: #fff;
    background-color: #3E90FE;
}
.dian{
    width: 6px;
    height: 6px;
    background: #FFFFFF;
    border-radius: 3px;
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
}
  </style>
