<template>
    <div>
        <div class="icon-box">
            <div class="relative">
                <div class="line-title" :style="{...lineTitleStyle,...parallelogramStyle}">
                 <span v-if="!column.titleHide" >{{ column.titleName || '分割线' }}</span>
                </div>
                <div class="parallelogram-box">
                    <div class="parallelogram" :style="parallelogramStyle"></div>
                </div>
                <div class="parallelogram-small" :style="parallelogramStyle"></div>
            </div>
        </div>
        <div class="line-desc" v-if="!column.LineDescHide"  :style="lineDescStyle">
            {{ column.LineDesc }}
        </div>
    </div>
  </template>
  <script>

  export default {
    name: "styleNineLine",
    props: {
      column: {
        type: Object,
        require: true,
        default() {
          return {};
        }
      },
      // 可能需要的整体数据
      businessData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
        return {
        };
    },
    computed: {
        lineDescStyle() {
            return {
                "font-size": this.column.LineDescFontsize,
                color: this.column.LineDescFontcolor,
                 "font-weight": this.column.LineDescFontbold ? "bold" : 400
            };
        },
        parallelogramStyle() {
            return {
                "background-color": this.column.LineTypeColor
            };
        },
        lineTitleStyle() {
            return {
                "font-size": this.column.titleFontsize,
                color: this.column.titleFontcolor,
                "font-weight": this.column.titleFontbold ? "bold" : 400,
                "background-color": `rgba(${this.column.LineTypeColor},0.12)`
            };
        }
    }
  };
  </script>
  <style scoped lang="scss">
.icon-box{
    display: flex;
    margin-top: 20px;
}
.relative{
    position: relative;
}
.line-title {
    min-width: 130px;
    height: 30px;
    padding: 0 20px 0 10px;
    border-radius: 4px 4px 4px 4px;
    color:#3E90FE;
    background: #e8f2ff;
}
.parallelogram{
    width: 12px;
    height: 30px;
    border-radius: 10px 10px 10px 10px;
    background: #e8f2ff;

    // transform: skewX(-12deg);
    // clip-path: polygon(35% 0, 100% 0, 70% 100%, 0% 100%);
}
.parallelogram-small{
    position: absolute;
    right: -30px;
    top: 0;
    width: 10px;
    height: 30px;
    border-radius: 10px 10px 10px 10px;
    background: #e8f2ff;

    // transform: skewX(-12deg);
    transform: skewX(-12deg);
}
.parallelogram-box{
    position: absolute;
    right: -28px;
    width: 30px;
    top: 0;
    padding-left: 5px;
    transform: skewX(-12deg);
    background-color: #fff;
}
  </style>
