<template>
    <div class="solid-line">
        <div class="line-title" v-if="!column.titleHide"  :style="lineTitleStyle">
            {{ column.titleName || '分割线' }}
        </div>
        <div class="line-desc"  v-if="!column.LineDescHide" :style="lineDescStyle">
            {{ column.LineDesc }}
        </div>
        <div class="line" :style="lineStyle"></div>
    </div>
  </template>
  <script>

  export default {
    name: "solidLine",
    props: {
      column: {
        type: Object,
        require: true,
        default() {
          return {};
        }
      },
      // 可能需要的整体数据
      businessData: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
        lineDescStyle() {
            return {
                "font-size": this.column.LineDescFontsize,
                color: this.column.LineDescFontcolor,
                 "font-weight": this.column.LineDescFontbold ? "bold" : 400
            };
        },
        lineTitleStyle() {
            return {
                "font-size": this.column.titleFontsize,
                color: this.column.titleFontcolor,
                "font-weight": this.column.titleFontbold ? "bold" : 100
            };
        },
        lineStyle() {
            return {
                "border-color": this.column.LineTypeColor,
                "border-top-width": this.column.LineSize
            };
        }
    }
  };
  </script>
  <style scoped lang="scss">
  .line{
    margin-top:14px;
    width: 100%;
    border-top: 1px solid #C1CAD7;
  }
  </style>
